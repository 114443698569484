"use client";
import { RevolvingDot } from "react-loader-spinner";

const Loading = () => {
  return (
    <div className="bg-white">
      <div className="container py-20">
        <div className="flex justify-center items-center">
          <RevolvingDot
            visible={true}
            height="80"
            width="80"
            color="#4fa94d"
            ariaLabel="revolving-dot-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
